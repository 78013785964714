import { connect } from "react-redux";
import { Card, Tabs } from "antd";
import { useEffect, useState } from "react";
import QRcode from "~/public/static/qrcode.png";

const Page = (props) => {
  const [activeKey, setActiveKey] = useState();

  const Title = (props) => (
    <div style={{ fontSize: "16px" }} className="qrcode-tab">
      {props.title}
    </div>
  );

  useEffect(() => {
    props.token ? setActiveKey("1") : setActiveKey("2");
  }, [props.token]);
  return (
    <Card>
      <Tabs activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
        <Tabs.TabPane tab={<Title title="下载APP" />} key="1">
          <div className="qrcode">
            <img
              src="https://duckfiles.oss-cn-qingdao.aliyuncs.com/eleduck/assets/app.png"
              alt="二维码"
            />
            <p>下载APP，能及时收到回复或进展</p>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<Title title="公众号" />} key="2">
          <div className="qrcode">
            <img src={QRcode.src} alt="二维码" />
            <p> 关注微信公众号，订阅每周远程职位</p>
          </div>
        </Tabs.TabPane>
      </Tabs>
      <style jsx>
        {`
          .qrcode {
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
          }

          .qrcode img {
            width: 128px;
            height: 128px;
            margin-bottom: 15px;
          }

          .qrcode p {
            margin: 0 0 5px 0;
            padding: 0;
            font-size: 13px;
            color: #7b7b7b;
          }
          .qrcode-tab {
            font-size: 16px;
          }
        `}
      </style>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  token: state.user.token,
});
export default connect(mapStateToProps)(Page);
