import { useEffect, useState } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAppModal } from "../../redux/actions/app";
import {
  createPostUpvote,
  deletePostUpvote,
  createPostDownvote,
  deletePostDownvote,
} from "../../redux/actions/post";

import Icon from "@ant-design/icons/lib/components/Icon";
import UpvoteIcon from "../app/UpvoteIcon";
import DownvoteIcon from "../app/DownvoteIcon";
import PostActionButton from "./PostActionButton";

import _ from "lodash";

type VoteAction = (token: string, postId: string, callback: () => void) => void;

const PostVoteAction = ({
  post,
  upvoted: up,
  downvoted: down,
  token,
  contentType,
  createPostUpvote,
  deletePostUpvote,
  createPostDownvote,
  deletePostDownvote,
  showAppModal,
}: {
  post: any;
  upvoted: boolean;
  downvoted: boolean;
  token: string;
  contentType?: string;
  createPostUpvote: VoteAction;
  deletePostUpvote: VoteAction;
  createPostDownvote: VoteAction;
  deletePostDownvote: VoteAction;
  showAppModal: any;
}) => {
  const [upvoted, setUpvoted] = useState<boolean>(up);
  const [downvoted, setDownvoted] = useState<boolean>(down);
  const [upvotesCount, setUpvotesCount] = useState<number>(0);
  const [downvotesCount, setDownvotesCount] = useState<number>(0);

  const handleVote = () => {
    if (token) {
      const action = upvoted ? deletePostUpvote : createPostUpvote;
      action(token, post.id, () => {
        setUpvoted(!upvoted);
        setUpvotesCount(upvoted ? upvotesCount - 1 : upvotesCount + 1);
        if (downvoted) {
          setDownvoted(false);
          setDownvotesCount(downvotesCount - 1);
        }
      });
    } else {
      showAppModal("PhoneSigninModal");
    }
  };

  const handleDownvote = () => {
    if (token) {
      const action = downvoted ? deletePostDownvote : createPostDownvote;
      action(token, post.id, () => {
        setDownvoted(!downvoted);
        setDownvotesCount(downvoted ? downvotesCount - 1 : downvotesCount + 1);
        if (upvoted) {
          setUpvotesCount(upvotesCount - 1);
          setUpvoted(false);
        }
      });
    } else {
      showAppModal("PhoneSigninModal");
    }
  };

  useEffect(() => {
    setUpvotesCount(post.upvotes_count);
    setDownvotesCount(post.downvotes_count);
    setUpvoted(up);
    setDownvoted(down);
  }, [post, up, down]);

  if (contentType === "Post") {
    return (
      <>
        <PostActionButton
          text={`顶 ${upvotesCount > 0 ? upvotesCount : ""}`}
          icon={<Icon component={UpvoteIcon} style={{ fontSize: "20px" }} />}
          className={upvoted ? "post-action-button-active" : ""}
          onClick={handleVote}
        />
        <PostActionButton
          text={`踩 ${downvotesCount > 0 ? downvotesCount : ""}`}
          icon={<Icon component={DownvoteIcon} style={{ fontSize: "20px" }} />}
          className={downvoted ? "post-action-button-active" : ""}
          onClick={handleDownvote}
        />
      </>
    );
  } else {
    return (
      <div className="post-list-vote-button">
        <Icon
          component={UpvoteIcon}
          style={{ fontSize: "18px" }}
          onClick={handleVote}
          className={upvoted ? "active" : ""}
        />
        <div className="post-vote-count">
          {upvotesCount - downvotesCount > 1000
            ? `${((upvotesCount - downvotesCount) / 1000).toFixed(1)}k`
            : upvotesCount - downvotesCount}
        </div>
        <Icon
          component={DownvoteIcon}
          style={{ fontSize: "18px" }}
          onClick={handleDownvote}
          className={downvoted ? "active" : ""}
        />
      </div>
    );
  }
};
const mapStateToProps = (state: any) => ({
  token: state.user.token,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      createPostUpvote,
      deletePostUpvote,
      createPostDownvote,
      deletePostDownvote,
      showAppModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostVoteAction);
