import { divide } from "lodash";

export default (props) => {
  const { count } = props;

  if (count <= 0) {
    return <span></span>;
  } else if (count >= 100) {
    return <span className="badge badge99">99+</span>;
  } else if (count >= 10) {
    return <span className="badge badge10">{count}</span>;
  } else {
    return <span className="badge">{count}</span>;
  }
};
