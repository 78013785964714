import { useRouter } from "next/router";
import { Menu, Dropdown, Button } from "antd";
import { CaretDownOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { updateLinkQuery } from "~/utils/route";
import { setCookie, removeCookie } from "../../utils/cookie";

const SORT_ITEMS = [
  {
    label: "默认",
    key: "default",
  },
  {
    label: "最新",
    key: "new",
  },
];

const PostSortDropdown = ({ sort, localization }) => {
  const router = useRouter();
  const buildMenuItem = (item) => {
    return {
      label: item.label,
      key: item.key,
    };
  };

  const menu = (
    <Menu
      items={SORT_ITEMS.map(buildMenuItem)}
      onClick={({ key }) => {
        if (localization) {
          if (key === "default") {
            removeCookie("sort");
          } else {
            setCookie("sort", key);
          }
        }

        const link = updateLinkQuery(router, (query) => {
          if (key === "default") {
            delete query["sort"];
          } else {
            query.sort = key;
          }
        });

        if (key) router.push(link.as);
      }}
    />
  );
  const currentSort = SORT_ITEMS.find((x) => x.key === sort) || SORT_ITEMS[0];

  return (
    <span>
      <SortAscendingOutlined className="mr-5" />
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button size="small">
          {currentSort.label} <CaretDownOutlined />
        </Button>
      </Dropdown>
    </span>
  );
};

export default PostSortDropdown;
