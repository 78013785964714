export default () => {
  return (
    <svg
      className="downvote"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
    >
      <path
        d="M512 955.733333a17.015467 17.015467 0 0 1-12.066133-5.000533l-426.666667-426.666667A17.066667 17.066667 0 0 1 85.333333 494.933333h238.933334V85.333333a17.066667 17.066667 0 0 1 17.066666-17.066666h341.333334a17.066667 17.066667 0 0 1 17.066666 17.066666v409.6h238.933334a17.066667 17.066667 0 0 1 12.066133 29.1328l-426.666667 426.666667A17.015467 17.015467 0 0 1 512 955.733333zM126.532267 529.066667L512 914.5344 897.467733 529.066667H682.666667a17.066667 17.066667 0 0 1-17.066667-17.066667V102.4H358.4v409.6a17.066667 17.066667 0 0 1-17.066667 17.066667H126.532267z"
        p-id="12824"
        fill="currentColor"
      ></path>
    </svg>
  );
};
