import Link from "next/link";

import { truncate } from "~/utils/app";
import { userUrl } from "~/utils/route";
import UserAvatar from "~/components/user/UserAvatar";

export default (props) => {
  const { user } = props;

  return (
    <Link href="/users/[id]" as={userUrl(user)}>
      <a className={props.className} target="_blank">
        {props.avatar && <UserAvatar showLevel user={user} size={23} />}
        <span className={`ml-5 ${props.className}`} title={user.nickname}>
          {truncate(user.nickname, 12)}
        </span>
        {props.isAuthor && <span>（作者）</span>}
      </a>
    </Link>
  );
};
