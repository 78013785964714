import { Empty } from "antd";
import blankIcon from "~/public/static/blank.png";

export default (props) => {
  const { children, description = "暂无数据" } = props;

  return (
    <div className="my-50">
      <Empty description={description} image={blankIcon.src}>
        {children}
      </Empty>
    </div>
  );
};
