const PostActionButton = ({
  text,
  src,
  icon,
  className,
  onClick,
}: {
  text: string;
  src?: string;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const cname = className
    ? className + " post-action-button"
    : "post-action-button";
  return (
    <div className={cname} onClick={() => onClick && onClick()}>
      {src && <img src={src} width={20} height={20} />}
      {icon && icon}
      <span>{text}</span>
    </div>
  );
};
export default PostActionButton;
