import Link from 'next/link'
import { useRouter } from 'next/router'

import { Pagination } from 'antd'

import { updateLinkQuery } from '~/utils/route'

export default props => {
  const router = useRouter()

  if (!props.pager || props.pager.total_pages <= 1) {
    return null
  }

  const renderPagerItem = (page, _type, originalElement) => {
    if (page) {
      const link = updateLinkQuery(router, query => {
        if (page && page > 1) {
          query.page = page
        } else {
          delete query['page']
        }
      })
      return (
        <Link href={link.href} as={link.as}>
          {originalElement}
        </Link>
      );
    } else {
      return originalElement;
    }
  };

  return (
    <div className='site-pagination'>
      <Pagination
        size='small'
        defaultCurrent={1}
        current={props.pager.current_page}
        pageSize={props.pager.limit_value}
        itemRender={renderPagerItem}
        total={props.pager.total_count}
        onChange={props.onPageChange}
        onShowSizeChange={props.onShowSizeChange}
        showSizeChanger={props.sizeChange ?? false }
      />

      <style jsx>{`
        text-align: right;
        margin-top: 20px;
      `}
      </style>
    </div>
  )
}
